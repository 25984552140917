body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
}




.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: black;
  color: white;
  font-size: small;
}

.header {
  display: flex;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
}

.signOutDiv{
  margin-left: auto;
  margin-right: 5px;
}

.heading{
  margin-left: auto;
}
.main {
    display: flex;
    flex-direction: column;
    flex :1 1;
    /* width: 1200px; */
  }
  
  .main> * {
    margin-bottom: 5px;
  }
  
  .patient-details {
    display: flex;
    width: 60%;
    margin-left: 100px;
    justify-content: space-evenly;
    /* border: solid 1px; */
  }
  
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .row >* {
      margin: 0 0 0 30px ;
  }
  
  .left {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    
  }
  .left > * { margin: 20px 0 0 0; }
  
  .middle{
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      
  }
  
  .middle > * {
      margin: 20px 0 0 0;
  }
  
  
  .right {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .right > * { 
    margin: 30px 0 0 0;
  }  

  .gap {
    margin-top: 30px;
    margin-left: 10px;
  }

  .empty{
      width: 250px;
      height: 250px;
  }

  .emptyblock{
    background-color:lightgray;
}




  

 
