.App {
  text-align: center;
  display: flex;
  min-height: 90vh;
  flex-direction: column;
}




.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: black;
  color: white;
  font-size: small;
}

.header {
  display: flex;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
}

.signOutDiv{
  margin-left: auto;
  margin-right: 5px;
}

.heading{
  margin-left: auto;
}